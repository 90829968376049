import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button, { ICON_POSITIONS } from '../components/uikit/buttons';
import Dropdown from '../components/uikit/dropdown';
import logo from '../assets/general/logo_desktop.svg'; // Import the logo

const NavBarDesktop = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleButtonClick = (dropdownId) => {
    setActiveDropdown(activeDropdown === dropdownId ? null : dropdownId);
  };

  const handleCloseDropdown = () => {
    setActiveDropdown(null);
  };

  return (
    <nav className="nav_bar_block">
      <div className="nav_bar_space_block">
      <div className="horizontal_block gap_8">
          <div className="relative_block">
            <Button type="secondary" size="m_with_icon_right" iconName="down" iconPosition={ICON_POSITIONS.RIGHT} onClick={() => handleButtonClick('startups')}>Startups</Button>
            <Dropdown isVisible={activeDropdown === 'startups'} size="big" onClose={handleCloseDropdown}>
                <div className="horizontal_block gap_12">
                  <div className="vertical_block gap_4 flex_start">
                    <p className ="title_s">All startups</p>
                    <p className ="paragraph_s text_secondary"> Startup companies from all over the world</p>
                  </div>
                </div>
                <div className="horizontal_block gap_16">Text here</div>
                <div className="horizontal_block gap_16">Text here</div>
      </Dropdown>
          </div>
          <div className="relative_block">
            <Button type="secondary" size="m_with_icon_right" iconName="down" iconPosition={ICON_POSITIONS.RIGHT} onClick={() => handleButtonClick('investors')}>Investors</Button>
            <Dropdown 
              isVisible={activeDropdown === 'investors'} 
              items={['Investor 1', 'Investor 2', 'Investor 3']} 
              size="big" 
              onClose={handleCloseDropdown} 
            />
          </div>
          <div className="relative_block">
            <Button type="secondary" size="m_with_icon_right" iconName="down" iconPosition={ICON_POSITIONS.RIGHT} onClick={() => handleButtonClick('partners')}>More</Button>
            <Dropdown 
              isVisible={activeDropdown === 'partners'} 
              items={['Partner 1', 'Partner 2', 'Partner 3']} 
              size="big" 
              onClose={handleCloseDropdown} 
            />
          </div>
        </div>
      <div className="horizontal_block gap_8">
      <Button type="icon_m" iconName="search" onClick={handleButtonClick}></Button>
      <Button type="primary" size="m" onClick={handleButtonClick}>Get started</Button>
      </div>
      </div>
      <div className="nav_bar_center_block">
      <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
    </nav>
  );
};

export default NavBarDesktop;
