import React from 'react';
import { Helmet } from 'react-helmet';
import seoConfig from '../components/utilities/seo_static_config';
import NavBarDesktop from '../components/nav_bar_desktop';
import Footer from '../components/footer'

function PrivacyPolicy() {
  const { title, description } = seoConfig.privacy_policy;


  return (
    <div className="landing_body">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <NavBarDesktop />
      <div className ="landing_main_block">
        <div className="landing_section">
          <div className="vertical_block gap_24">
            <h1>Privacy Policy</h1>
            <p className="paragraph_l">Effective Date: June 13, 2024</p>
          </div>
          <div className="landing_section_block">
          <div className="paragraph_m vertical_block gap_20 flex_start">
          <p>Raizer (<strong>“Raizer”, “we”, “us”, and “our”</strong>) is a digital fundraising tool. Our Privacy Policy (“Privacy Policy”) covers the personal information we collect about you when you use our products or services, or otherwise interact with us, including on our website at https://raizer.app, and our related online and offline offerings (collectively, the <strong>“Services”</strong>).</p>
          <p>This Privacy Policy does not apply to any third-party websites, services or applications, even if they are accessible through our Services. In addition, a separate privacy notice, which is available upon request if it applies to you, governs personal information we receive from our current or prospective employees and contractors.</p>
          <p>By using our Services, you agree to be bound by this Privacy Policy. If you don’t agree to this Privacy Policy, do not use the Services. If you access and use the Services on behalf of a company (such as your employer) or other legal entity, you represent and warrant that you have the authority to bind that company or other legal entity to this Privacy Policy. In that case, “you” and “your” will refer to that company or other legal entity.</p>
          <p><strong>1. Personal information we collect</strong></p>
          <p>We collect personal information when you provide it to us, when you use our Services, and when other sources provide it to us, as further described below.</p>
          <p><strong>A. Information You Provide to Us</strong></p>
          <p><strong>Account Creation. </strong>When you create an account we collect information such as your name, email address, password, your company name, role within your team or enterprise, and an optional profile photo.</p>
          <p><strong>Your Communications with us. </strong>We collect personal information from you such as email address, phone number, or mailing address when you request information about our Services, register for our newsletter, request customer or technical support, or otherwise communicate with us. We also collect the contents of messages or attachments that you may send to us, as well as other information you choose to provide, and that may be associated with your communications.</p>
          <p><strong>Payment Information.</strong>We may sell services through our Services. When you make purchases through the Services, we may use a third-party application to process your payments. These third-party applications may collect certain financial information from you to process a payment on behalf of Raizer, including your name, email address, mailing address, payment card information, and other billing information. Raizer does not store or receive your payment information, but it may store and receive information associated with your payment information (e.g., your billing details).</p>
          <p><strong>Surveys. </strong>We may contact you to participate in surveys. If you decide to participate, you may be asked to provide certain information which may include personal information.</p>
          <p><strong>Use of the Services. </strong>We collect information you provide to the Services for the purpose of providing the Services to you, which may include personal information.</p>
          <p><strong>Customer Service, Training and Quality Assurance. </strong>If you call or otherwise interact with Raizer sales, customer service or support personnel, we may collect the information you provide to our representatives. In addition, we may record telephone calls or video conferences between you and our representatives for training or quality assurance purposes.</p>
          <p><strong>B. Information Collected Automatically</strong></p>
          <p><strong>Automatic Data Collection. </strong>We may collect certain information automatically when you use the Services. This information may include your Internet protocol (IP) address, user settings, MAC address, cookie identifiers, mobile advertising and other unique identifiers, details about your browser, operating system or device, location information (inferred based off of your IP address), Internet service provider, pages that you visit before, during and after using the Services, information about the links you click, and information about how you interact with and use the Services.</p>
          <p><strong>Cookies, Pixel Tags/Web Beacons, and Analytics Information. </strong>We, as well as third parties that may provide content, advertising, or other functionality on the Services, may use cookies, pixel tags, local storage, and other technologies (“Technologies”) to automatically collect information through the Services. Technologies are essentially small data files placed on your device that allow us and our partners to record certain pieces of information whenever you visit or interact with our Services.</p>
          <ul>
            <li><strong>Cookies: </strong>Cookies are small text files placed in device browsers to store their preferences. Most browsers allow you to block and delete cookies. However, if you do that, the Services may not work properly.</li>
            <li><strong>Pixel Tags/Web Beacons: </strong>A pixel tag (also known as a web beacon) is a piece of code embedded in the Services that collects information about engagement on the Services. The use of a pixel tag allows us to record, for example, that a user has visited a particular web page or clicked on a particular advertisement. We may also include web beacons in e-mails to understand whether messages have been opened, acted on, or forwarded.</li>
          </ul>
          <p><strong>Analytics: </strong>We may also use third-party service providers to collect and process analytics and other information on our Services. These third-party service providers may use cookies, pixel tags, web beacons or other storage technology to collect and store analytics and other information. They have their own privacy policies addressing how they use the analytics and other information and we do not have access to, nor control over, third parties’ use of cookies or other tracking technologies.</p>
          <p><strong>Information from Other Sources</strong></p>
          <p><strong>Enterprise Customers. </strong>If you use our Services on behalf of an organization (e.g., your employer), that organization may provide us with information about you so that we can provision your account.</p>
          <p><strong>Third Party Services and Organizations. </strong>We may obtain information about you from other sources, including through third-party services and organizations. For example, if you access our Services through a third-party application, such as a social networking site or a third-party login service, we may collect information about you from that third party that you have made available via your privacy settings.</p>
          <p><strong>2. How we use your information</strong></p>
          <p>We use your personal information for a variety of business purposes, including to:</p>
          <p><strong>Provide the Services or Requested Information, such as:</strong></p>
          <ul>
            <li>Fulfilling our contract with you;</li>
            <li>Identifying and communicating with you;</li>
            <li>Managing your information and account;</li>
            <li>Processing your payments and otherwise servicing your purchase orders;</li>
            <li>Responding to questions, comments, and other requests;</li>
            <li>Providing access to certain areas, functionalities, and features of our Services;</li>
            <li>Answering requests for customer or technical support.</li>
          </ul>
          <p><strong>Serve Administrative and Communication Purposes, such as:</strong></p>
          <ul>
            <li>Pursuing legitimate interests, such as direct marketing, research and development (including marketing research), network and information security, and fraud prevention;</li>
            <li>Measuring interest and engagement in our Services, including analyzing your usage of the Services;</li>
            <li>Developing new products and services and improving the Services;</li>
            <li>Ensuring internal quality control and safety;</li>
            <li>Authenticating and verifying individual identities;</li>
            <li>Carrying out audits;</li>
            <li>Communicating with you about your account, activities on our Services and Privacy Policy changes;</li>
            <li>Preventing and prosecuting potentially prohibited or illegal activities;</li>
            <li>Enforcing our agreements;</li>
            <li>Complying with our legal obligations;</li>
          </ul>
          <p><strong>Marketing of Products and Services: </strong>We may use personal information to tailor and provide you with content and advertisements.</p>
          <p><strong>Consent: </strong>We may use personal information for other purposes that are clearly disclosed to you at the time you provide personal information or with your consent.</p>
          <p><strong>De-identified and Aggregated Information Use: </strong>We may use personal information and other data about you to create de-identified and/or aggregated information. De-identified and/or aggregated information is not personal information, and we may use and disclose such information in a number of ways, including research, internal analysis, analytics, and any other legally permissible purposes.</p>
          <p><strong>How We Use Automatic Collection Technologies: </strong>Our uses of Technologies fall into the following general categories:</p>
          <ul>
            <li>Operationally Necessary;</li>
            <li>Performance Related;</li>
            <li>Functionality Related;</li>
            <li>Advertising or Targeting Related.</li>
          </ul>
          <p>Please note that this Privacy Policy covers only our use of Technologies and does not govern the use of Technologies by any third parties.</p>
          <p><strong>Cross-Device Tracking: </strong>Your browsing activity may be tracked across different websites and different devices or apps. For example, we may attempt to match your browsing activity on your mobile device with your browsing activity on your laptop. To do this our technology partners may share data, such as your browsing patterns, geo-location and device identifiers, and will match the information of the browser and devices that appear to be used by the same person.</p>
          <p><strong>3. Disclosing your information to third parties</strong></p>
          <p>We may share your personal information with the following categories of third parties:</p>
          <p><strong>Service Providers. </strong>We may share any personal information we collect about you with our third-party service providers. The categories of service providers to whom we entrust personal information include service providers for: (i) the provision of the Services; (ii) the provision of information, products, and other services you have requested; (iii) marketing and advertising; (iv) payment and transaction processing; (v) customer service activities; and (vi) the provision of IT and related services.</p>
          <p><strong>Business Partners. </strong>We may provide personal information to business partners to provide you with a product or service you have requested. We may also provide personal information to business partners with whom we jointly offer products or services.</p>
          <p><strong>Affiliates. </strong>We may share personal information with our affiliated entities.</p>
          <p><strong>Advertising Partners. </strong>We do not share your information, including personal information, to advertise any third party’s products or services via the Services. We may use and share your personal information with third-party advertising partners to market our own Services and grow our Services’ user base, such as to provide targeted marketing about our own Services via third-party services. If you prefer not to share your personal information with third-party advertising partners, you may follow the instructions below.</p>
          <p><strong>Your Organization. </strong>If you access the Services on behalf of an organization, we may share your information with that organization at its request. Please note that your information may also be subject to your organization’s privacy policy. We are not responsible for the privacy or security practices of our customers.</p>
          <p><strong>Disclosures to Protect Us or Others. </strong>We may access, preserve, and disclose any information we store in association with you to external parties if we, in good faith, believe doing so is required or appropriate to: (i) comply with law enforcement or national security requests and legal process, such as a court order or subpoena; (ii) protect your, our, or others’ rights, property, or safety; (iii) enforce our policies or contracts; (iv) collect amounts owed to us; or (v) assist with an investigation and prosecution of suspected or actual illegal activity.</p>
          <p><strong>Disclosure in the Event of Merger, Sale, or Other Asset Transfer. </strong>If we are involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership, purchase or sale of assets, or transition of service to another provider, then your information may be sold or transferred as part of such a transaction, as permitted by law and/or contract.</p>
          <p><strong>4. Your Choices</strong></p>
          <p><strong>General. </strong>You may have the right to opt out of certain uses of your personal information.</p>
          <p><strong>Email Communications.</strong>You will receive transaction-related emails regarding products or services you have requested. We may also send you certain non-promotional communications regarding us and our Services, and you will not be able to opt out of those communications (e.g., communications regarding the Services or updates to this Privacy Policy).</p>
          <p><strong>Cookies and Interest-Based Advertising. </strong>You may stop or restrict the placement of Technologies on your device or remove them by adjusting your preferences as your browser or device permits. The online advertising industry also provides websites from which you may opt out of receiving targeted ads from data partners and other advertising partners that participate in self-regulatory programs. You can access these websites and learn more about targeted advertising and consumer choice and privacy, at <a href="https://www.networkadvertising.org/">www.networkadvertising.org</a>, <a href="https://www.youronlinechoices.eu/">www.youronlinechoices.eu</a>, <a href="https://youradchoices.ca/choices/">www.youradchoices.ca/choices</a>, and <a href="https://www.aboutads.info/choices/">www.aboutads.info/choices/</a>.</p>
          <p>Please note you must separately opt out in each browser and on each device.</p>
          <p><strong>“Do Not Track”. </strong>Do Not Track (<strong>“DNT”</strong>) is a privacy preference that users can set in certain web browsers. Please note that we do not respond to DNT signals or similar mechanisms transmitted by web browsers.</p>
          <p><strong>5. Your Privacy Rights</strong></p>
          <p>In accordance with applicable laws, you may have the right to:</p>
          <ul>
            <li><strong>Access</strong>personal information about you consistent with legal requirements.</li>
            <li><string>Request Correction </string>of your personal information where it is inaccurate or incomplete.</li>
            <li><string>Request Deletion </string>of your personal information, subject to certain exceptions prescribed by law.</li>
            <li><strong>Request Restriction </strong>of our processing of your personal information</li>
            <li><strong>Withdraw your Consent </strong>to our processing of your personal information.</li>
          </ul>
          <p>If you would like to exercise any of these rights, please contact us as set forth below. We will process such requests in accordance with applicable laws. To protect your privacy, we will take steps to verify your identity before fulfilling your request.</p>
          <p>Please note that if you use our Services on behalf of an organization (e.g., your employer), that organization may be responsible for fulfilling the individual rights requests referenced above.</p>
          <p><strong>6. Google User Data</strong></p>
          <p><strong>IMPORTANT: </strong>Raizer’s use and transfer of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</a>, including the Limited Use requirements.</p>
          <p><strong>We do not access your Sent emails through your Gmail account. </strong>We use access to draft sending in your Gmail account to ensure the operability of the Send Later feature.</p>
          <p>We may add a <strong>tracking pixel</strong> to the body of emails sent through our service to track email opens and wrap your links with a tracking domain to track link clicks. We use the data acquired through these tracking methods to maintain email campaign operability and provide campaign reports.</p>
          <p>You may revoke Raizer’s access to your Gmail account in the Account’s settings page. If you decide to do so, we will lose access to your Gmail account and will no longer be able to provide you campaign sending services through Gmail API.</p>
          <p>We delete all data collected from your Gmail account upon request.</p>
          <p>Not all Google User Data may contain personal data. Some statistical data will be anonymized.</p>
          <p>With regard to the access to Google User Data as specified above, we will:</p>
          <ul>
            <li>only use access to read, write, modify, or control Gmail message bodies (including attachments), metadata, headers, and settings to provide a web email client that allows users to compose, send, read, and process emails and will not transfer this Gmail data to others unless doing so is necessary to provide and improve these features, comply with applicable law, or as part of a merger, acquisition, or sale of assets;</li>
            <li>not use this Gmail data for serving advertisements;</li>
            <li>not allow humans to read this data unless we have your affirmative agreement for specific messages, doing so is necessary for security purposes such as investigating abuse, to comply with applicable law, or for the Platform's internal operations and even then only when the data have been aggregated and anonymized.</li>
          </ul>
          <p><strong>7. Other Conditions</strong></p>
          <p><strong>International Data Transfers.</strong> All information processed by us may be transferred, processed, and stored anywhere in the world, including but not limited to the United States or other countries, which may have data protection laws that are different from the laws where you live. We endeavor to safeguard your information consistent with the requirements of applicable laws.</p>
          <p><strong>Data Retention.</strong> We store the personal information we receive as described in this Privacy Policy for as long as you use our Services or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements, and/or comply with applicable laws. We may also retain your personal information for our compliance with a legal, accounting, or reporting obligation or for archiving purposes in historical research purposes or statistical purposes.</p>
          <p><storng>Security of your information.</storng> We take steps to ensure that your information is treated securely and in accordance with this Privacy Policy. Unfortunately, no system is 100% secure, and we cannot ensure or warrant the security of any information you provide to us. To the fullest extent permitted by applicable laws, we do not accept liability for unauthorized disclosure.</p>
          <p>By using the Services or providing personal information to us, you agree that we may communicate with you electronically regarding security, privacy, and administrative issues relating to your use of the Services. If we learn of a security system’s breach, we may attempt to notify you electronically by posting a notice on the Services, by mail or by sending an email to you.</p>
          <p>You are responsible for selecting any password and its overall security strength, ensuring the security of your own information within the bounds of our services.</p>
          <p><strong>Third-Party Websites/Applications.</strong>The Services may contain links to other websites/applications and other websites/applications may reference or link to our Services. These third-party services are not controlled by us. We encourage our users to read the privacy policies of each website and application with which they interact. We do not endorse, screen or approve, and are not responsible for, the privacy practices or content of such other websites or applications. Visiting these other websites or applications is at your own risk.</p>
          <p><strong>Children’s Privacy.</strong> The Services are not directed to children under 13, and we do not knowingly collect personal information from children. If you learn that your child has provided us with personal information without your consent, you may contact us as set forth below. If we learn that we have collected a child’s personal information in violation of applicable law, we will promptly take steps to delete such information and terminate the child’s account.</p>
          <p><strong>Supervisory Authority.</strong> If you are located in the European Economic Area or the UK, you have the right to lodge a complaint with a supervisory authority if you believe our processing of your personal information violates applicable law.</p>
          <p><strong>Changes to Our Privacy Policy.</strong> We may revise this Privacy Policy from time to time at our sole discretion. If there are any material changes to this Privacy Policy, we will notify you as required by applicable law. You understand and agree that you will be deemed to have accepted the updated Privacy Policy if you continue to use the Services after the new Privacy Policy takes effect.</p>
          <p><strong>8. Contact Us</strong></p>
          <p>If you have any questions or concerns regarding our privacy practices or this Privacy Policy, please contact us via email to hey@raizer.app</p>
          <p>Bnfts, inc. 340 S Lemon Ave #2143, Walnut, CA 91789, United States</p>
       </div>
      </div>
    </div>
    </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;