function importAll(r) {
  let images = {};
  r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
  return images;
}

export function loadImages(context) {
  let images = {};

  if (context === 'whats_new') {
    images = importAll(require.context('../../assets/images/whats_new', false, /\.(png|jpe?g|svg|webp)$/));
  }

  if (context === 'blog') {
    images = importAll(require.context('../../assets/images/blog', false, /\.(png|jpe?g|svg|webp)$/));
  }

  return images;
}
