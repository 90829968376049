// src/pages/WhatsNew.js
import React from 'react';
import { Helmet } from 'react-helmet';
import seoConfig from '../components/utilities/seo_static_config';
import NavBarDesktop from '../components/nav_bar_desktop';
import Footer from '../components/footer';
import NewsItem from '../components/uikit/news_item';
import newsData from '../data/whats_new_content.json';

function WhatsNew() {
  const { title, description } = seoConfig.whats_new;
  const sortedNewsData = newsData.sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <div className="landing_body">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <NavBarDesktop />
      <div className="landing_main_block">
        <div className="landing_section">
          <div className="vertical_block gap_24">
            <h1>What's new</h1>
            <p className="paragraph_l">Explore the latest updates</p>
          </div>
          {sortedNewsData.map((news, index) => (
            <NewsItem key={index} {...news} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default WhatsNew;
