import React, { useEffect, useRef, forwardRef } from 'react';
import '../../styles/dropdown.css';
import '../../styles/typography.css';

const Dropdown = forwardRef(({ isVisible, size, onClose, children }, ref) => {
  const internalRef = useRef(null);
  const combinedRef = ref || internalRef;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (combinedRef.current && !combinedRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [combinedRef, onClose]);

  if (!isVisible) {
    return null;
  }

  return (
    <div ref={combinedRef} className={size === 'big' ? 'dropdown_big' : 'dropdown_small'}>
      {React.Children.map(children, child => (
        <div className={size === 'big' ? 'dropdown_item_big' : 'dropdown_item_small'}>
          {child}
        </div>
      ))}
    </div>
  );
});

export default Dropdown;
