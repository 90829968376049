// src/pages/BlogPost.js
import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { loadImages } from '../components/utilities/load_images';
import NavBarDesktop from '../components/nav_bar_desktop';
import Footer from '../components/footer';
import blogData from '../data/blog_content.json';
import socialIcon from '../assets/icons/social_x.svg';


const BlogPost = () => {
  const { slug } = useParams();
  const blogPost = blogData.find(post => post.slug === slug);
  const Images = loadImages('blog');
  const coverImageSrc = Images[blogPost.cover_image];
  const authorLogoSrc = Images[blogPost.author_logo];

  return (
    <div className="landing_body">
    <Helmet>
      <title>{blogPost.name}</title>
      <meta name="description" content={blogPost.subtitle} />
    </Helmet>
      <NavBarDesktop />
      <div className="landing_main_block">
        <div className="landing_section">
          <div className="vertical_block gap_24">
            <h1>{blogPost.name}</h1>
            <p className="paragraph_l">{blogPost.date}</p>
            <div className="horizontal_block gap_16">
              <img src={authorLogoSrc} alt={blogPost.author} className="user_logo size_48" />
              <div className="vertical_block gap_4 flex_start">
                <div className="horizontal_block gap_4 flex_start align_center">
                  <p className="title_s">{blogPost.author}</p>
                  <a href={blogPost.author_link} target="_blank" rel="noopener noreferrer" className="size_16"><img src={socialIcon} alt="Social Icon" className="size_16" />
                  </a>
                </div>
                <p className="paragraph_s" dangerouslySetInnerHTML={{ __html: blogPost.author_title }}></p>
              </div>
            </div>
          </div>
          <div className="landing_section_block vertical_block gap_32 flex_start flex_grow">
            <img src={coverImageSrc} alt={blogPost.name} className="landing_blog_image" />
            <div className="paragraph_m vertical_block gap_20 flex_start" dangerouslySetInnerHTML={{ __html: blogPost.content }}></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogPost;
