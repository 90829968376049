import React from 'react';
import { Helmet } from 'react-helmet';
import seoConfig from '../components/utilities/seo_static_config';
import NavBarDesktop from '../components/nav_bar_desktop';
import Footer from '../components/footer'

function PrivacyPolicy() {
  const { title, description } = seoConfig.terms_of_use;

  
  return (
    <div className="landing_body">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <NavBarDesktop />
      <div className ="landing_main_block">
        <div className="landing_section">
          <div className="vertical_block gap_24">
            <h1>Terms of Use</h1>
            <p className="paragraph_l">Effective Date: June 13, 2024</p>
          </div>
          <div className="landing_section_block">
          <div className="paragraph_m vertical_block gap_20 flex_start">
          <p>Hello and welcome to Raizer! Thank you for signing up to use the Services. Please read these End User Terms of Use (<strong>"Terms"</strong> or <strong>"Terms of Use"</strong>) carefully before using the www.raizer.app website operated by Bnfts, Inc. (<strong>"Raizer", "us", "we", and "our"</strong>).</p>
          <p>For purposes of these Terms, <strong>“you”</strong> and <strong>“your”</strong> means you as the user of the Service. Some specific definitions for capitalized terms are set out in Exhibit A of these Terms.</p>
          <p>You acknowledge that these Terms, along with our <a href="/privacy-policy">Privacy Policy</a>, govern your access and use of the Services. By accessing or using the Services you agree to be bound by these Terms. If you disagree with any part of these Terms, then you do not have permission to access the Services.</p>
          <p><strong>1. Changes to these Terms</strong></p>
          <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to access or use our Services after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Services.</p>
          <p><strong>2. Service Terms</strong></p>
          <p><strong>Your Relationship to Subscriber.</strong> Your access and use of the Services is conditioned upon the Subscriber’s granting you such access and use rights under their subscription to the Services and such Subscriber’s compliance with its agreements with us, including payment obligations. You acknowledge and agree that upon termination of the applicable Subscriber’s rights to use the Services, your use of the Services will automatically terminate. You hereby grant such Subscriber and its Administrator(s) permission to access, use, download, export, disclose, share, restrict and/or remove your User Content. You acknowledge and agree that Subscriber and its Administrator(s) may restrict or terminate your access to or use of all or part of the Services.</p>
          <p><strong>Third-Party Services & Partners.</strong> The Services may contain links to, integrate with, or provide the ability for you to access third-party services (for example, financial data providers). Your use of or reliance on any third-party services are at your own risk. We are not responsible for and we do not make any promises, endorsements, representations or warranties regarding such third-party services. We may stop making any third-party services available at any time with or without advance notice to you.</p>
          <p><strong>Compliance with Law.</strong> You will comply with applicable laws and regulations in connection with your use of the Services.</p>
          <p><strong>3. Rights & Restrictions</strong></p>
          <p><strong>Your Account.</strong> You must register for a Raizer account in order to use the Services. In registering for or opening an account, you agree to provide accurate and complete registration information and to keep such information up to date. You will take reasonable steps to prevent unauthorized use of your account and to keep your password(s) secure and confidential, even if you are an Administrator(s). We encourage you to use “strong” passwords with your account (for example, passwords that use a combination of upper and lowercase letters, numbers and symbols, with a minimum of eight characters). Raizer will not be liable for any losses caused by any unauthorized use of your account. You must notify us immediately of any breach of security or unauthorized use of your account.</p>
          <p><strong>Your Responsibility.</strong> You are responsible for all activities that occur under your account.</p>
          <p><strong>Your Rights.</strong> Subject to your full and ongoing compliance with these Terms and the Subscriber’s full and ongoing compliance with applicable agreements and obligations, we grant you a limited, non-exclusive, non-transferable, non-sublicensable, revocable right, during the applicable Subscriber’s subscription term to access and use the Services.</p>
          <p><strong>Your Restrictions.</strong> The User rights granted are limited and subject to the restrictions outlined below. You will not (and will not permit any third party) to:</p>
          <ul>
            <li>Provide, sell, resell, transfer, sublicense, lend, distribute, rent, or otherwise allow others to access or use the Services;</li>
            <li>Use the Services for illegal, harassing, unethical, or disruptive purposes;</li>
            <li>Modify, translate, adapt, make derivative works of, disassemble, decompile, reverse compile or reverse engineer any part of the Services;</li>
            <li>Access the Services in order to build a similar or competitive product or service;</li>
            <li>Violate any applicable law or regulation in connection with your access to or use of the Services; or</li>
            <li>Access or use the Services in any way not expressly permitted by these Terms.</li>
            <li>You may only use the Services if you are old enough to consent to share your data under applicable law. For example, you must be 13 years or older under United States law, or 16 years or older under European Union law.</li>
            <li>Any unauthorized use of the Services terminates all granted you rights and we may terminate these User Terms or your access and use of the Services.</li>
          </ul>
          <p><strong>4. Billing & Refunds</strong></p>
          <p>Due to the nature of our product, we currently do not offer refunds, either partial or in full.</p>
          <p>You can easily cancel your subscription at any time you like. We will no longer charge you anything once you cancel your subscription.</p>
          <p>We may change our pricing, pricing policies, features and access restrictions at any time.</p>
          <p><strong>5. Content</strong></p>
          <p><strong>Required Content.</strong> You are required to provide certain data, information, and materials to enable us to provide the Services to you and/or the Subscriber (i.e., your User Content). You agree that if you do not provide the necessary information will not be able to access or use the Services.</p>
          <p><strong>Responsibility for User Content.</strong> You agree that you are solely responsible for all your User Content submitted, posted, communicated, provided, or otherwise made available through or for the Services.</p>
          <p><strong>Ownership of User Content.</strong> You retain all ownership rights in your User Content.</p>
          <p><strong>License.</strong> You grant us a royalty-free, perpetual, irrevocable, worldwide, non-exclusive and fully-sublicensable right and license (i) to use your User Content as necessary to perform, analyze and improve the Services; and (ii) to collect, use and disclose usage data; and/or (iii) to use and disclose your User Content for other uses identified in our <a href="/privacy-policy">Privacy Policy</a>. You represent and warrant you have to right to grant these rights and licenses. Without such rights and licenses, you acknowledge that we will not be able to provide the Services to you and that you will no longer be able to access or use the Services.</p>
          <p><strong>Removal of Provided Content.</strong> Raizer may remove your User Content for any reason, including if such User Content violates these User Terms or applicable law.</p>
          <p><strong>6. Termination</strong></p>
          <p><strong>Term of the Terms of Use.</strong> The term of these Terms starts on day you agree to these Terms or on the day you access the Services and it continues while you use the Services.</p>
          <p><strong>Termination of the Terms of Use.</strong> Your use of the Services will automatically terminate upon the termination of your Subscriber’s subscription or when instructed by your Subscriber. In addition, we may suspend or terminate your rights to use the Services (including your Account) at any time for any reason at our sole discretion, including for any use of the Services in violation of these User Terms.</p>
          <p><strong>Effect of Termination.</strong> Upon the effective date of termination of these Terms for any reason: (i) your right to access or use the Services will terminate immediately and (ii) we will cease providing the Services to you and we will deactivate or delete your account, and all associated materials, including your User Content.</p>
          <p><strong>Survival.</strong> All provisions of these User Terms which by their nature should survive, will survive termination of these User Terms, including without limitation, ownership provisions, warranty disclaimers, and limitation of liability.</p>
          <p><strong>7. Indemnification.</strong></p>
          <p>You agree to indemnify and hold Raizer, its parents, subsidiaries, affiliates, officers, employees, agents, partners and licensors harmless from any losses, costs, liabilities and expenses (including reasonable attorneys’ fees) relating to or arising out of: (a) your Content or (b) your violation of any applicable laws, rules or regulations.</p>
          <p><strong>8. Disclaimer</strong></p>
          <p>YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. THE SERVICE IS PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR COURSE OF PERFORMANCE.</p>
          <p>RAIZER, ITS SUBSIDIARIES, ITS AFFILIATES, AND ITS LICENSORS DO NOT WARRANT THAT (A) THE SERVICES WILL FUNCTION UNINTERRUPTED, SECURE OR WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (B) ANY ERRORS OR DEFECTS WILL BE CORRECTED; OR (C) THE RESULTS OF USING THE SERVICES WILL MEET YOUR REQUIREMENTS.</p>
          <p>RAIZER DOES NOT WARRANT OR SUPPORT, AND WILL NOT HAVE ANY RESPONSIBILITY OR LIABILITY OF ANY KIND FOR, ANY APPLICATION(S) AND/OR MATERIAL(S) THAT ARE DEVELOPED BY A PARTY OUTSIDE OF THE RAIZER ORGANIZATION, INCLUDING CODE COMPONENTS AND INTEGRATION TOOLS PROVIDED BY THIRD PARTIES, NOT RAIZER.</p>
          <p><strong>9. Limitations of Liability.</strong></p>
          <p>TO THE EXTENT PERMITTED BY APPLICABLE LAW, NEITHER RAIZER NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES (“SUPPLIERS”) WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR GOODWILL, SERVICES INTERRUPTION, SYSTEM FAILURE, OR THE COST OF SUBSTITUTE SERVICES ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT RAIZER HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE.</p>
          <p>TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL RAIZER OR SUPPLIERS’ TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES EXCEED ONE HUNDRED UNITED STATES DOLLARS ($100).</p>
          <p>THE LIMITATIONS OF LIABILITY ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN RAIZER AND YOU.</p>
          <p><strong>10. Arbitration</strong></p>
          <p>You and we both agree to resolve disputes related to your use of the Services or these Terms (each, a “Claim”) in binding arbitration instead of court, except that either party may bring suit in court to enjoin the infringement or other misuse of intellectual property rights.</p>
          <p><strong>Arbitration Definition.</strong> Arbitration does not involve a judge or jury. Instead, a neutral person (the “arbitrator”) hears each party’s side of the dispute and makes a decision that is finally binding on both parties. The arbitrator can award the same relief as a court could, including monetary damages. While court review of an arbitration award is limited, if a party fails to comply with the arbitrator’s decision, then the other party can have the arbitration decision enforced by a court.</p>
          <p><strong>Individual Basis.</strong> YOU AGREE TO RESOLVE YOUR CLAIMS WITH US SOLELY ON AN INDIVIDUAL BASIS, AND NOT AS PART OF A CLASS, REPRESENTATIVE OR CONSOLIDATED ACTION. WE AGREE TO DO THE SAME, WHETHER OR NOT YOU OPT OUT OF ARBITRATION. ACCORDINGLY, UNLESS YOU OPT OUT OF ARBITRATION, YOU AND WE BOTH ARE WAIVING THE RIGHT TO PURSUE OR HAVE A DISPUTE RESOLVED AS A PLAINTIFF OR MEMBER IN ANY CLASS, REPRESENTATIVE OR CONSOLIDATED ACTION.</p>
          <p><strong>The Arbitration Rules.</strong> The arbitration will be conducted under the American Arbitration Association (“AAA”) Consumer Arbitration Rules (the “AAA Rules”). The AAA Rules are available at <a href="https://www.adr.org">www.adr.org</a> or by calling 1-800-778-7879.</p>
          <p><strong>The Arbitration Proceeding.</strong> The arbitration will be conducted by the AAA or a comparable arbitration body in the event the AAA is unable to conduct the arbitration. Payment of all filing, administration and arbitrator fees will be governed by the AAA Rules.</p>
          <p>Unless the arbitrator finds your Claim frivolous, we’ll pay for all filing, administration and arbitrator fees if your Claim is for less than $10,000, and we won’t seek our attorneys’ fees and costs if we prevail in the arbitration. The arbitration may be conducted in writing, remotely (e.g., by videoconference), in San Francisco, California, United States or at some other location that we both agree to.</p>
          <p>To begin an arbitration proceeding against us, send a letter requesting arbitration and describing your Claim to hey@raizer.app, 340 S Lemon Ave #2143, Walnut, CA 91789, United States. If we request arbitration against you we will give you notice at the email address you use to grant access to the Services.</p>
          <p><strong>30 Days Right to Opt-Out.</strong> If you don’t want to agree to arbitrate your Claims as explained above, then you can opt-out of this arbitration agreement by notifying us of your decision in writing at hey@raizer.app, and physical notice at 340 S Lemon Ave #2143, Walnut, CA 91789, United States. You must opt-out within 30 days of the date you first agree to these Terms or any updated Terms.</p>
          <p><strong>11. Other Conditions</strong></p>
          <p><strong>Entire Terms.</strong> These Terms constitute the entire agreement between you and Raizer regarding our Services and supersede and replace any prior agreements we might have had between us regarding the Services.</p>
          <p><strong>Assignment.</strong> These Terms (and your rights and obligations under these Terms) may not be assigned or otherwise transferred by you without Raizer’ prior written consent. We may assign these Terms as part of a corporate reorganization, upon a change of control, consolidation, merger, or sale of all or substantially all of its assets related to these Terms or for commercial purposes. Any purported assignment in violation of this section is null and void.</p>
          <p><strong>Waiver.</strong> Any waiver or failure to enforce any provision of these Terms of Use on one occasion will not be deemed a waiver of any other provision or of such provision on any other occasion. If any particular provision of these Terms of Use is held invalid or unenforceable, that part will be modified to reflect the original intention of the parties, and the other parts will remain in full force and effect.</p>
          <p><strong>Governing Law.</strong> These Terms will be governed by the laws of the State of California without regard to its conflict of laws provisions.</p>
          <p><strong>Privacy Policy.</strong> Our <a href="/privacy-policy">Privacy Policy</a> governs how we collect, use and disclose information from the Services.</p>
          <p><strong>Feedback.</strong> We welcome feedback, comments and suggestions for improvements to the Services (collectively <strong>“Feedback”</strong>). You acknowledge and expressly agree that any contribution of Feedback does not and will not give or grant you any right, title or interest in the Services or in any such Feedback. All Feedback becomes the sole and exclusive property of Raizer, and Raizer may use and disclose Feedback in any manner and for any purpose whatsoever without further notice or compensation to you and without retention by you of any proprietary or other right or claim.</p>
          <p><strong>Beta Features.</strong> Product features clearly identified as Beta features made available by Raizer are provided to you for testing purposes only, and Raizer does not make any commitment to provide Beta Features in any future versions of the Services. You are not obligated to use Beta Features. Raizer may immediately and without notice remove Beta Features for any reason without liability to you. Notwithstanding anything to the contrary in the Terms, all Beta Features are provided "AS IS" without warranty of any kind and without any performance obligations.</p>
          <p><strong>Service Providers.</strong> For the avoidance of doubt, Raizer may engage third parties as service providers to the Services (for example, as of the date of these Terms, Raizer hosts the Services on Amazon Web Services).</p>
          <p><strong>Force Majeure.</strong> Raizer will not be liable for any delay or failure to perform resulting from causes or conditions beyond our reasonable control.</p>
          <p><strong>Notices.</strong> Any notices or other communications provided by Raizer under these Terms, including those regarding modifications to these Terms, will be given by Raizer: (i) via e-mail; or (ii) by posting to the Site. For notices made by e-mail, the date of receipt will be deemed the date on which such notice is transmitted.</p>
          <p><strong>12. Contact Us</strong></p>
          <p>If you have any questions or concerns regarding the Services or these Terms, please contact us via email to hey@raizer.app</p>
          <p>Bnfts, inc. 340 S Lemon Ave #2143, Walnut, CA 91789, United States</p>
          <p><strong>Exhibition A — Definitions</strong></p>
          <p>The following specific definitions apply to terms used in the Terms of Use:</p>
          <p><strong>“Administrator(s)”</strong> means the individual(s) Subscriber names as administrator(s) to oversee the provision of the Services to Subscriber’s Users (as defined below) and that Subscriber authorize to make decisions on Subscriber’s behalf as it relates to (i) the Services and (ii) Subscriber’s Users’ access to and use of the Services. Such Administrators also have the ability to access, monitor, use, modify, export and disclose user’s data and other specific inforrmation and persmissions.</p>
          <p><strong>“Subscriber”</strong> means the entity or company that has subscribed to the Services and authorized you to access and use the Services pursuant to these User Terms.</p>
          <p><strong>“User”</strong> means you as an individual who Subscriber has authorized to access and use the Services, for whom Subscriber has purchased a subscription and to whom Subscriber has provided login credentials. Users include all Subscriber’s Administrators, employees and may also include, for example, Subscriber’s former employees who require continued access to and use of the Services for a particular time period, Subscriber’s consultants, contractors, agents, and third parties with which Subscriber’s transact business.</p>
          <p><strong>“User Content”</strong> means the data (including financial data), information, images,  and/or materials Users provide (or that is provided on the Users’ behalf) for our provision of the Services to Subscriber and/or Users, which may include names, contact information, Social Security numbers, compensation information, and other sensitive, proprietary, personal, or identifying information.</p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    </div>
  );
}

export default PrivacyPolicy;