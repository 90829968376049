import React from 'react';
import { Helmet } from 'react-helmet';
import seoConfig from '../components/utilities/seo_static_config';
import NavBarDesktop from '../components/nav_bar_desktop';
import Footer from '../components/footer';
import BlogItem from '../components/uikit/blog_item';
import blogData from '../data/blog_content.json';

function Blog() {
  const { title, description } = seoConfig.blog;

  const sortedBlogData = blogData.sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <div className="landing_body">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <NavBarDesktop />
      <div className="landing_main_block">
        <div className="landing_section">
          <div className="vertical_block gap_24">
            <h1>Blog</h1>
            <p className="paragraph-l">Explore the latest updates</p>
          </div>
          {sortedBlogData.map((blog) => (
            <BlogItem key={blog.slug} {...blog} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blog;
