function Startups() {
  return (
    <div className = "vertical-block gap-24">
      <h1>All startups</h1>
      <p className = "paragraph_l">Discover all startup companies you can partner with.</p>
    </div>
  );
}

export default Startups;
