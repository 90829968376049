import React from 'react';
import { loadImages } from '../../components/utilities/load_images';

const Images = loadImages('whats_new');

const NewsItem = ({ name, date, cover_image, content }) => {
  const imageSrc = Images[cover_image];



  return (
    <div className="landing_section_block vertical_block gap_32 flex_start flex_grow">
      <p className="title-l">{name}</p>
      <p className="paragraph_s text_secondary">{date}</p>
      <img src={imageSrc} alt={name} className="landing_cover_image" />
      <div className="paragraph_m vertical_block gap_20 flex_start" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default NewsItem;
