
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollTop from './components/utilities/scrolltop';
import Homepage from './pages/homepage_temp';
import Investors from './pages/investors_temp';
import Startups from './pages/startups_temp';
import PrivacyPolicy from './pages/privacy';
import TermsOfUse from './pages/terms-of-use';
import WhatsNew from './pages/whats-new';
import Blog from './pages/blog';
import BlogPost from './pages/blog_post';

function App() {
  return (
    <Router>
      <ScrollTop /> 
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/investors" element={<Investors />} />
        <Route path="/startups" element={<Startups />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/whats-new" element={<WhatsNew />} />  
        <Route path="/blog" element={<Blog />} />  
        <Route path="/blog/:slug" element={<BlogPost />} />
      </Routes>
    </Router>
  );
}

export default App;
