import React from 'react';
import '../../styles/buttons.css';
import down from '../../assets/icons/down.svg';
import plus from '../../assets/icons/plus.svg';
import search from '../../assets/icons/search.svg';

const icons = {
  'down': down,
  'plus': plus,
  'search': search,
  // Add more icons here
};

const ICON_POSITIONS = {
  LEFT: 'left',
  RIGHT: 'right',
};

const Button = ({ children, type, size, iconName, iconPosition, onClick }) => {
  const className = size 
    ? `button button_${type} button_${size}` 
    : `button button_${type}`;

  const iconSrc = iconName ? icons[iconName] : null;
  const isIconOnly = !children && iconSrc;

  return (
    <button className={className} onClick={onClick}>
      {iconSrc && iconPosition === ICON_POSITIONS.LEFT && !isIconOnly && <img src={iconSrc} alt={iconName} className="size_20" />}
      {children}
      {iconSrc && iconPosition === ICON_POSITIONS.RIGHT && !isIconOnly && <img src={iconSrc} alt={iconName} className="size_20" />}
      {isIconOnly && <img src={iconSrc} alt={iconName} className="size_20" />}
    </button>
  );
};

export { ICON_POSITIONS };
export default Button;
