import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../components/uikit/buttons';
import logo from '../assets/general/logo_64x64.svg'; // Import the logo

const Footer = () => {
    return (
    <footer className="landing_footer_block">
     <div className = "vertical_block gap_32">
        <img src={logo} alt="Logo" />
        <p className="title_l">Build strong relationships to close best deals</p>
        <div className="horizontal_block gap_12">
        <Button type="primary" size="m" onClick={() => window.location.href = 'https://example.com/current-tab'}>Get started for free</Button>
        <Button type="secondary" size="m" onClick={() => window.open('https://x.com/raizerapp', '_blank')}>Follow us on X</Button>
        </div>
      </div>
      <div className = "divider"></div>
    <div className="horizontal_block gap_32 flex_grow flex_start">
      <div className="vertical_block gap_16 flex_grow flex_start">
        <p className="title_s">Resources</p>
        <Link to="/best-practices" className="paragraph_s text_link">Best Practices</Link>
        <Link to="/blog" className="paragraph_s text_link">Blog</Link>
        <Link to="/whats-new" className="paragraph_s text_link">What's new</Link>
        <Link to="/pricing" className="paragraph_s text_link">Pricing</Link>
        <Link to="/faq" className="paragraph_s text_link">FAQ</Link>
        <Link to="/contact-us" className="paragraph_s text_link">Contact Us</Link>
      </div>
      <div className="vertical_block gap_16 flex_grow flex_start">
        <p className="title_s">Top companies</p>
        <Link to="/best-practices" className="paragraph_s text_link">Companies on NASDAQ</Link>
        <Link to="/best-practices" className="paragraph_s text_link">Top startups in US</Link>
        <Link to="/whats-new" className="paragraph_s text_link">Top-500 startups in UK</Link>
        <Link to="/pricing" className="paragraph_s text_link">Top-100 SaaS startups</Link>
        <Link to="/faq" className="paragraph_s text_link">French startups</Link>
        <Link to="/blog" className="paragraph_s text_link">Startups from LatAm</Link>
        <Link to="/contact-us" className="paragraph_s text_link">Best AI startups</Link>
      </div>
      <div className="vertical_block gap_16 flex_grow flex_start">
        <p className="title_s">Top investors</p>
        <Link to="/best-practices" className="paragraph_s text_link">Companies on NASDAQ</Link>
        <Link to="/best-practices" className="paragraph_s text_link">Top startups in US</Link>
        <Link to="/whats-new" className="paragraph_s text_link">Top-500 startups in UK</Link>
        <Link to="/pricing" className="paragraph_s text_link">Top-100 SaaS startups</Link>
        <Link to="/faq" className="paragraph_s text_link">French startups</Link>
        <Link to="/blog" className="paragraph_s text_link">Startups from LatAm</Link>
        <Link to="/contact-us" className="paragraph_s text_link">Best AI startups</Link>
      </div>
      <div className="vertical_block gap_16 flex_grow flex_start">
        <p className="title_s">Other lists</p>
        <Link to="/best-practices" className="paragraph_s text_link">Companies on NASDAQ</Link>
        <Link to="/best-practices" className="paragraph_s text_link">Top startups in US</Link>
        <Link to="/whats-new" className="paragraph_s text_link">Top-500 startups in UK</Link>
        <Link to="/pricing" className="paragraph_s text_link">Top-100 SaaS startups</Link>
        <Link to="/faq" className="paragraph_s text_link">French startups</Link>
        <Link to="/blog" className="paragraph_s text_link">Startups from LatAm</Link>
        <Link to="/contact-us" className="paragraph_s text_link">Best AI startups</Link>
      </div>
    </div>
      <div className = "divider"></div>
    <div className="horizontal_block gap_16">
      <p className="paragraph_s">© 2024 Raizer</p>
      <p className="paragraph_s">All rights reserved</p>
      <Link to="/terms-of-use" className="paragraph_s text_link">Terms of Use</Link>
      <Link to="/privacy-policy" className="paragraph_s text_link">Privacy Policy</Link>
      <Link to="/sitemap.xml" className="paragraph_s text_link">Sitemap</Link>
    </div>
    </footer>
    );
  }
export default Footer;
