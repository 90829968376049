import React from 'react';
import { Helmet } from 'react-helmet';
import seoConfig from '../components/utilities/seo_static_config';
import NavBarDesktop from '../components/nav_bar_desktop';
import Footer from '../components/footer'
import BlogItem from '../components/uikit/blog_item';
import blogData from '../data/blog_content.json';
import Button from '../components/uikit/buttons';
import FiveStartupsLogo from '../assets/images/reviews/500.svg';
import AnterLogo from '../assets/images/reviews/antler.svg';
import ProductHuntLogo from '../assets/images/reviews/product_hunt.svg';
import TechStarsLogo from '../assets/images/reviews/techstars.svg';
import YCLogo from '../assets/images/reviews/yc.svg';

function Homepage() {
    const { title, description } = seoConfig.homepage;
    const sortedBlogData = blogData.sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0,3);

  return (
    <div className="landing_body">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <NavBarDesktop />
      <div className="landing_main_block gap_128">
        <div className="vertical_block gap_24">
          <h1>Discover startups, investors, and more</h1>
          <p className="paragraph_l">300,000+ startups and investors to grow your business.</p>
        </div>
        <section className="landing_section container_size_690">
          <div className="vertical_block gap_24">
            <h2>The world’s top databases of startups and investors</h2>
            <p className="paragraph_m text_align_center">We've curated an extensive database of startups and investors from over 150 countries, all conveniently accessible in one place.</p>
          </div>
        </section>
        <section className="landing_section">
          <h2>Why everyone loves Raizer</h2>
          <div className="vertical_block gap_32">
            <p className="caption">Trusted by 5,000+ teams & investors from:</p>
            <div className="horizontal_block gap_32">
               <img src={FiveStartupsLogo} alt={"500 startups logo"}></img>
               <img src={AnterLogo} alt={"Antler logo"}></img>
               <img src={ProductHuntLogo} alt={"Product Hunt logo"}></img>
               <img src={TechStarsLogo} alt={"Techstars logo"}></img>
               <img src={YCLogo} alt={"Y Combinator logo"}></img>
            </div>
          </div>
        </section>
        <section className="landing_section">
          <h2>Blog</h2>
          {sortedBlogData.map((blog) => (
            <BlogItem key={blog.slug} {...blog} />
          ))}
          <Button type="primary" size="m" onClick={() => window.location.href = '/blog'}>Go to the blog</Button>
        </section>
        <section className="landing_section container_size_690">
          <div className="vertical_block gap_24">
            <h3>Get full access of startup and investor directories along with contact info</h3>
            <p className="paragraph_m text_align_center">At Raizer, we want to simplify the process of finding partners and opportunities. To make this easier, we've curated an extensive database of startups and investors from over 150 countries, all conveniently accessible for you to use. Our databases are meticulously maintained, providing accurate and up-to-date contact information, including emails, ensuring you have the most reliable information at your fingertips.</p>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Homepage;
