
const seoConfig = {
    homepage: {
      title: 'Home',
      description: 'Welcome to our homepage. Explore our services and products.',
    },
    investors: {
      title: 'Investors',
      description: 'Find the best investment opportunities.',
    },
    startups: {
      title: 'Startups',
      description: 'Discover innovative startups and entrepreneurs.',
    },
    privacy_policy: {
      title: 'Privacy Policy',
      description: 'Read our privacy policy to learn how we handle your data.',
    },
    terms_of_use: {
      title: 'Terms of Use',
      description: 'Understand the terms and conditions of using our services.',
    },
    whats_new: {
      title: "What's New",
      description: 'Explore the latest updates and features.',
    },
    blog: {
      title: 'Blog',
      description: 'How-to tutorials, guides, tips, and posts for founders and investors.',
    },
  };
  
  export default seoConfig;
  