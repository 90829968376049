import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/uikit/buttons';
import { loadImages } from '../../components/utilities/load_images';
import socialIcon from '../../assets/icons/social_x.svg';

const Images = loadImages('blog');

const BlogItem = ({ name, slug, date, cover_image, subtitle, author, author_title, author_logo, author_link }) => {
  const coverImageSrc = Images[cover_image];
  const authorLogoSrc = Images[author_logo];

  return (
    <div className="landing_section_block horizontal_block gap_32 align_center">
      <img src={coverImageSrc} alt={name} className="landing_blog_image" />
      <div className="vertical_block gap_24 flex_start">
        <p className="paragraph_s text_secondary">{date}</p>
        <p className="title_l">{name}</p>
        <p className="paragraph_m">{subtitle}</p>
        <div className="horizontal_block gap_16">
          <img src={authorLogoSrc} alt={author} className="user_logo size_48" />
          <div className="vertical_block gap_4 flex_start">
            <div className="horizontal_block gap_4 flex_start align_center">
              <p className="title_s">{author}</p>
              <a href={author_link} target="_blank" rel="noopener noreferrer" className="size_16"><img src={socialIcon} alt="Social Icon" className="size_16" />
              </a>
            </div>
            <p className="paragraph_s" dangerouslySetInnerHTML={{ __html: author_title }}></p>
          </div>
        </div>
        <Link to={`/blog/${slug}`}>
          <Button type="secondary_s" size="s">Read article</Button>
        </Link>
      </div>
    </div>
  );
};

export default BlogItem;
