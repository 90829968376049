function Investors() {
  return (
    <div className = "vertical-block gap-24">
      <h1>Investors Page</h1>
      <p className="paragraph_l">Welcome to the Investors Page.</p>
    </div>
  );
}

export default Investors;
